import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "advanced-settings"
    }}>{`Advanced settings`}</h1>
    <p>{`It is possible to configure the Storygram in depth.  `}</p>
    <p>{`Warning: this might have a performance impact depending on the size of your data.`}</p>
    <h2 {...{
      "id": "verbose"
    }}>{`Verbose`}</h2>
    <p>{`Log information about the Storygram on the console for debug. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`verbose: boolean;
`}</code></pre>
    <h2 {...{
      "id": "root"
    }}>{`Root`}</h2>
    <p>{`CSS-Selector for the DOM element to which the Storygram is appended.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  root: string;
`}</code></pre>
    <h2 {...{
      "id": "generation-amount"
    }}>{`Generation amount`}</h2>
    <p>{`The Storygram is optimised with a genetic algorithm to reduce the amount of actor crossings and other factors. With the variable generationAmt it is possible to set the number of generations of the genetic algorithm. The default value should be good enough for most applications, altough if you have a large amount of events and/or actors to show, you can raise it. This can affect performance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  generationAmt: number;
`}</code></pre>
    <h2 {...{
      "id": "population-size"
    }}>{`Population size`}</h2>
    <p>{`The Storygram is optimised with a genetic algorithm to reduce the amount of actor crossings and other factors. With the variable populationSize it is possible to set the number of genes (Storygrams) of the genetic algorithm. The default value should be good enough for most applications, altough if you have a large amount of events and/or actors to show, you can raise it. This can affect performance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`  populationSize: number;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      